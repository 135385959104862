[data-stage="main"]{
    font-size: 3em;
}

[data-stage="main"].active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

[data-stage="main"] .header {
    /*height: 25%;*/
}

[data-stage="main"] .header .logo {
    font-size: 3em;
}


[data-stage="main"] .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex-grow: 1;
    /*font-size: 3em;*/
    box-sizing: border-box;
}

[data-stage="main"] .menu .item {
    /*background-color: #0d95e8;*/
    /*width: 60%;*/
    /*text-align: center;*/
    /*margin: 20px auto;*/
}