/*
    памятка порядка параметров:
    animation:
        animation-name
        animation-duration
        animation-timing-function
        animation-delay
        animation-iteration-count
        animation-direction;
 */

.unblur {
    animation: unblur 1s;
}

@keyframes unblur {
    from {
        filter: blur(50px);
    }
    to {
        filter: blur(0px);
    }
}

.duration-0-5s{animation-duration: 0.5s;}
.duration-1s{animation-duration: 1s;}
.duration-1-5s{animation-duration: 1.5s;}
.duration-2-5s{animation-duration: 2.5s;}
.duration-3s{animation-duration: 3s;}
.duration-3-5s{animation-duration: 3.5s;}
.duration-4s{animation-duration: 4s;}
.duration-4-5s{animation-duration: 4.5s;}

.delay-0-5s{animation-delay: 0.5s;}
.delay-1s{animation-delay: 1s;}
.delay-2s{animation-delay: 2s;}
.delay-2-5s{animation-delay: 2.5s;}
.delay-3s{animation-delay: 3s;}
.delay-3-5s{animation-delay: 3.5s;}
.delay-4s{animation-delay: 4s;}
.delay-4-5s{animation-delay: 4.5s;}

@keyframes tl_move {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes tl_move_center {
    0% {
        width: 100%;
    }
    50% {
        /*background-color: revert;*/
    }
    100% {
        /*background-color: red;*/
        width: 0;
    }
}