:root {
    /* bootstrap colors */
    --teal: #20c997;

    --cyan: #17a2b8;
    --blue: #007bff;

    --indigo: #6610f2;
    --purple: #6f42c1;

    --pink: #e83e8c;
    --red: #dc3545;

    --orange: #fd7e14;
    --yellow: #ffc107;

    --green: #28a745;
    --success: #28a745;

    --white: #fff;
    --light: #f8f9fa;

    --light-gray: #a7b2ba;
    --gray: #6c757d;
    --dark: #343a40;

}

html, body {
    user-select: none;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0;
    margin: 0;

    font-family: 'Amatic SC', cursive;
    font-size: 1em; /* 1em - 16px */

    /* Я не совсем уверен, но мне кажется, что так лучше */
    color: var(--dark);
    background-color: var(--light);
}

.appbox {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    min-height: 100%;
}

[data-stage],
[data-header] {
    display: none;
}

[data-stage] {
    flex-grow: 1;
}

[data-stage].active,
[data-header].active {
    display: block;
    /*background-color: red;*/
}

