[data-stage="game"].active {
    display: flex;
}

[data-stage="game"] {
    font-size: 2.5em;
    flex-direction: column;
}

[data-stage="game"] .header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    text-align: center;
}

[data-stage="game"] .header .item .val:before {
    content: attr(data-value);
}

[data-stage="game"] .field {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

[data-stage="game"] .field .progress {
    height: 0.2em;
    background-color: var(--light-gray);
    box-shadow: inset 0 0 5px 0 var(--gray);
}

[data-stage="game"] .field .progress .bar {
    /*width: 50%;*/
    height: 100%;
    background-color: var(--light);

    animation-name: tl_move;
    /*animation-timing-function: ease-out;*/
    animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
    animation-fill-mode: forwards;
    animation-play-state: paused;
    animation-duration: inherit;
}

[data-stage="game"] .field .progress.center {
    height: 0.2em;
    box-shadow: none;
    background-color: transparent;
}

[data-stage="game"] .field .progress.center .bar {
    /*width: 50%;*/
    height: 100%;
    margin: 0 auto;
    background-color: var(--gray);
    box-shadow: none;
    /*background-color: var(--light-gray);*/
    /*box-shadow: inset 0 0 5px 0 var(--gray);*/

    animation-name: tl_move_center;

}

[data-stage="game"] .field .progress.move .bar {
    animation-play-state: running;
}

[data-stage="game"] .field .card {
    flex-grow: 1;

    display: flex;
    flex-basis: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-evenly;

    box-sizing: border-box;

    overflow: hidden;
}

/* Немного увеличиваем размер при большом экране */
@media (min-width: 768px) {
    [data-stage="game"] .field .card {
        font-size: 1.3em;
    }
}

[data-stage="game"] .field .card .item {
    height: 2.5em;
    width: 2.5em;

    margin: .05em;

    /*background-color: #dc3545;*/

    background-image: url(/assets/img/coin.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    box-sizing: border-box;
}

[data-stage="game"] .field .card[data-count="1"] .item {
    height: 3.5em;
    width: 3.5em;
}

[data-stage="game"] .field .card[data-count="2"] .item {
    height: 3em;
    width: 3em;
}

[data-stage="game"] .field .card[data-count="3"] .item {
    height: 2.5em;
    width: 2.5em;
}

[data-stage="game"] .field .card[data-count="4"] .item {
    height: 2.7em;
    width: 2.7em;
    margin: 0;
    flex-basis: 50%;
}

[data-stage="game"] .field .card[data-count="5"] .item {
    height: 2.5em;
    width: 2.5em;
    margin: 0;
    flex-basis: 30%;
}

[data-stage="game"] .field .card[data-count="6"] .item {
    height: 2.5em;
    width: 2.5em;
    flex-basis: 30%;
}