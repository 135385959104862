/*@import url('https://fonts.googleapis.com/css?family=Boogaloo|Caveat:700|Gloria+Hallelujah|Nothing+You+Could+Do|Permanent+Marker|Rock+Salt|Sedgwick+Ave|Shadows+Into+Light');*/

/* cyrillic */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url(../fonts/amaticsc/BvkGNM5i0n2wywBsmOxcFoX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url(../fonts/amaticsc/OpbFR1Tmt2r4Z48lwWGNOYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url(../fonts/amaticsc/6oAC5EqjVnFivtPX-TgvlYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url(../fonts/amaticsc/6UByihrsVPWtZ99tNMIgMIX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url(../fonts/amaticsc/DPPfSFKxRTXvae2bKDzp5JBw1xU1rKptJj_0jans920.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}


/*---------------------------------------------*/
/*---------------------------------------------*/
/*---------------------------------------------*/
/*---------------------------------------------*/



/* latin */
@font-face {
    font-family: 'Boogaloo';
    font-style: normal;
    font-weight: 400;
    src: local('Boogaloo Regular'), local('Boogaloo-Regular'), url(../fonts/boogaloo/selWIQWiQA98uHaoODndYvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* cyrillic */
@font-face {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    src: local('Caveat Bold'), local('Caveat-Bold'), url(../fonts/caveat/YmuBHPFDVqRPpUrOhRkXoRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    src: local('Caveat Bold'), local('Caveat-Bold'), url(../fonts/caveat/rqY3dQaGIQau09Mh1Ra09hJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    src: local('Caveat Bold'), local('Caveat-Bold'), url(../fonts/caveat/x6VSYBqZaI7QnEcE_qETMFtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* latin */
@font-face {
    font-family: 'Gloria Hallelujah';
    font-style: normal;
    font-weight: 400;
    src: local('Gloria Hallelujah'), local('GloriaHallelujah'), url(../fonts/gloriahallelujah/CA1k7SlXcY5kvI81M_R28cNDay8z-hHR7F16xrcXsJw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* latin */
@font-face {
    font-family: 'Nothing You Could Do';
    font-style: normal;
    font-weight: 400;
    src: local('Nothing You Could Do'), local('NothingYouCouldDo'), url(../fonts/nothingyoucoulddo/jpk1K3jbJoyoK0XKaSyQAW3tIVbDRfcAbwPiWONb82U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* latin */
@font-face {
    font-family: 'Permanent Marker';
    font-style: normal;
    font-weight: 400;
    src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'), url(../fonts/permanentmarker/9vYsg5VgPHKK8SXYbf3sMsW72xVeg1938eUHStY_AJ4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* latin */
@font-face {
    font-family: 'Rock Salt';
    font-style: normal;
    font-weight: 400;
    src: local('Rock Salt Regular'), local('RockSalt-Regular'), url(../fonts/rocksalt/Q94aHXFHGip10K5uxi1jOFtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* vietnamese */
@font-face {
    font-family: 'Sedgwick Ave';
    font-style: normal;
    font-weight: 400;
    src: local('Sedgwick Ave Regular'), local('SedgwickAve-Regular'), url(../fonts/sedgwickave/pbgmsWX_2A5V-qqzaczoE_8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Sedgwick Ave';
    font-style: normal;
    font-weight: 400;
    src: local('Sedgwick Ave Regular'), local('SedgwickAve-Regular'), url(../fonts/sedgwickave/pbgmsWX_2A5V-qqzaczoEz0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Sedgwick Ave';
    font-style: normal;
    font-weight: 400;
    src: local('Sedgwick Ave Regular'), local('SedgwickAve-Regular'), url(../fonts/sedgwickave/pbgmsWX_2A5V-qqzaczoE-gdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* latin */
@font-face {
    font-family: 'Shadows Into Light';
    font-style: normal;
    font-weight: 400;
    src: local('Shadows Into Light'), local('ShadowsIntoLight'), url(../fonts/shadowsintolight/clhLqOv7MXn459PTh0gXYFK2TSYBz0eNcHnp4YqE4Ts.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
